
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexhx1zF91dj9I9Cp4pSYKG1BepVar538coHCTxJ5FUClIMeta } from "/home/runner/work/augwa/augwa/web/app/pages/index.vue?macro=true";
import { default as indexQkkI1SB6XC0ihHSCDAUxeLuvkomE5BUOK_VTrsvw1Q4Meta } from "/home/runner/work/augwa/augwa/web/app/pages/terms/index.vue?macro=true";
import { default as indexvDau5HI1QGSfFnMiDGE0SqP9f8dj0ta1FjrBjYjla40Meta } from "/home/runner/work/augwa/augwa/web/app/pages/privacy/index.vue?macro=true";
import { default as indexsBJ5HrRJzZk85kojWNRf3J0hhuporo3EXnuBxGwoeRYMeta } from "/home/runner/work/augwa/augwa/web/app/pages/get-started/index.vue?macro=true";
import { default as indexBwjT54wexjALoj4uvPGlzy97hLnojYMh2hATaklICAIMeta } from "/home/runner/work/augwa/augwa/web/app/pages/pricing-report/[leadId]/index.vue?macro=true";
import { default as find_45the_45right_45rate_45for_45your_45businessGjIhQ3_CTiZjDKpoVK1OhPAksgYuNk_45yFsI47X8x0ZcMeta } from "/home/runner/work/augwa/augwa/web/app/pages/calculators/find-the-right-rate-for-your-business.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexhx1zF91dj9I9Cp4pSYKG1BepVar538coHCTxJ5FUClIMeta || {},
    component: () => import("/home/runner/work/augwa/augwa/web/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexhx1zF91dj9I9Cp4pSYKG1BepVar538coHCTxJ5FUClIMeta || {},
    component: () => import("/home/runner/work/augwa/augwa/web/app/pages/index.vue")
  },
  {
    name: "terms___en",
    path: "/en/terms",
    component: () => import("/home/runner/work/augwa/augwa/web/app/pages/terms/index.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/home/runner/work/augwa/augwa/web/app/pages/privacy/index.vue")
  },
  {
    name: "get-started___en",
    path: "/en/get-started",
    meta: indexsBJ5HrRJzZk85kojWNRf3J0hhuporo3EXnuBxGwoeRYMeta || {},
    component: () => import("/home/runner/work/augwa/augwa/web/app/pages/get-started/index.vue")
  },
  {
    name: "pricing-report-leadId___en",
    path: "/en/pricing-report/:leadId()",
    meta: indexBwjT54wexjALoj4uvPGlzy97hLnojYMh2hATaklICAIMeta || {},
    component: () => import("/home/runner/work/augwa/augwa/web/app/pages/pricing-report/[leadId]/index.vue")
  },
  {
    name: "calculators-find-the-right-rate-for-your-business___en",
    path: "/en/calculators/find-the-right-rate-for-your-business",
    meta: find_45the_45right_45rate_45for_45your_45businessGjIhQ3_CTiZjDKpoVK1OhPAksgYuNk_45yFsI47X8x0ZcMeta || {},
    component: () => import("/home/runner/work/augwa/augwa/web/app/pages/calculators/find-the-right-rate-for-your-business.vue")
  }
]