import { captureException, dropUndefinedKeys, getClient, getTraceMetaTags } from "@sentry/core";
export function extractErrorContext(errorContext) {
  const structuredContext = {
    method: void 0,
    path: void 0,
    tags: void 0
  };
  if (errorContext) {
    if (errorContext.event) {
      structuredContext.method = errorContext.event._method || void 0;
      structuredContext.path = errorContext.event._path || void 0;
    }
    if (Array.isArray(errorContext.tags)) {
      structuredContext.tags = errorContext.tags || void 0;
    }
  }
  return dropUndefinedKeys(structuredContext);
}
export function addSentryTracingMetaTags(head) {
  const metaTags = getTraceMetaTags();
  if (metaTags) {
    head.push(metaTags);
  }
}
export function reportNuxtError(options) {
  const { error, instance, info } = options;
  const metadata = {
    info
    // todo: add component name and trace (like in the vue integration)
  };
  if (instance?.$props) {
    const sentryClient = getClient();
    const sentryOptions = sentryClient ? sentryClient.getOptions() : null;
    if (sentryOptions?.attachProps && instance.$props !== false) {
      metadata.propsData = instance.$props;
    }
  }
  setTimeout(() => {
    captureException(error, {
      captureContext: { contexts: { nuxt: metadata } },
      mechanism: { handled: false }
    });
  });
}
